import { template as template_5571fe69d9c640e6b2945f853e56888f } from "@ember/template-compiler";
const FKControlMenuContainer = template_5571fe69d9c640e6b2945f853e56888f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
