import { template as template_f96bc78d4c604bac99fc13f58254375e } from "@ember/template-compiler";
const FKText = template_f96bc78d4c604bac99fc13f58254375e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
